<template>
  <div
    class="history-users"
    :class="{
      'mise-en-page-bureau': estEcranBureau,
      'mise-en-page-mobile': !estEcranBureau
    }"
  >
    <div class="search">
      <span class="searchIcon" title="search">
        <font-awesome-icon icon="search" @click="filter"
      /></span>
      <input
        v-debounce:400="filter"
        class="input-search"
        type="text"
        v-model="full_name"
        placeholder="Rechercher un utilisateur"
        ref="autofocus"
      />
      <div
        v-if="getHistoryUsersLoading"
        class="init-loading three-dots-loading margin-loading"
      >
        Chargement en cours
      </div>
    </div>
    <div v-if="initLoading" class="init-loading three-dots-loading">
      Chargement en cours
    </div>
    <div v-else class="content-tab-history-users">
      <b-table
        small
        show-empty
        id="my-table-history"
        class="table-history-users"
        :items="searchByColumn"
        :fields="fields"
        :current-page="page"
        :per-page="0"
        sort-icon-left
        no-sort-reset
        :sort-by.sync="sort_by"
        :sort-desc.sync="sortDesc"
        no-local-sorting
        @sort-changed="sort"
        empty-text="Il n'y a aucun enregistrement à afficher"
        empty-filtered-text="Aucun enregistrement ne correspond à votre demande"
      >
        <template v-slot:cell(nom)="data">
          <div class="nom_user">{{ data.item.user.full_name }}</div>
        </template>
        <template v-slot:cell(filter)="data">
          <div v-if="data.item && data.item.filter">
            <div
              class="nom_user_filtre"
              v-for="(filter, index) in data.item.filter"
              :key="index"
            >
              {{ filter }}
            </div>
          </div>
          <div v-else>Aucun filtre</div>
        </template>
        <template v-slot:cell(type_projet)="data">
          <div class="nom_user">{{ data.item.dtype.name }}</div>
        </template>
        <template v-slot:cell(time_export)="data">
          <div class="nom_user">{{ data.item.timestamp }}</div>
        </template>
      </b-table>
      <div class="pagination">
        <div v-if="getHistoryUsersLoading" class="three-dots-loading loading">
          Chargement en cours
        </div>
        <b-pagination
          v-model="page"
          :per-page="per_page"
          :total-rows="countPaginationUser"
          align="right"
          size="sm"
          prev-text="Précédent"
          next-text="Suivant"
          aria-controls="my-table-history"
          @change="pagination"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      fields: [
        {
          key: 'user',
          label: 'Utilisateur',
          thClass: 'width-th-nom',
          tdClass: 'width-th-nom'
        },
        {
          key: 'action',
          label: 'Action effectué',
          thClass: 'width-th-filtre',
          tdClass: 'width-th-filtre'
        },
        {
          key: 'statut',
          label: 'Entité',
          thClass: 'width-th-type',
          tdClass: 'width-th-type'
        },
        {
          key: 'modification_date',
          label: 'Date modification',
          thClass: 'width-th-date',
          tdClass: 'width-th-date'
        }
      ],
      page: 1,
      per_page: 10,
      full_name: null,
      initLoading: true,
      estEcranBureau: true,
      sort_by: 'nom',
      sort_by_desc: 'nom',
      sortDesc: false
    }
  },
  methods: {
    ...mapActions(['fetchListHistoryUsers']),
    sort(sort) {
      this.sort_by = sort.sortBy
      this.sort_by_desc = sort.sortBy
      if (!sort.sortDesc) {
        this.fetchListHistoryUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name
        })
      } else {
        this.fetchListHistoryUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name
        })
      }
    },
    onResize() {
      this.estEcranBureau = window.innerWidth >= 827 // ajustez le point de rupture selon vos besoins
    },
    pagination(pagination) {
      this.page = pagination
      if (!this.sortDesc) {
        this.fetchListHistoryUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name
        })
      } else {
        this.fetchListHistoryUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name
        })
      }
    },
    filter() {
      this.page = 1
      if (!this.sortDesc) {
        this.fetchListHistoryUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by: this.sort_by,
          full_name: this.full_name
        })
      } else {
        this.fetchListHistoryUsers({
          page: this.page,
          per_page: this.per_page,
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'getlistHistoryUsers',
      'getHistoryUsersError',
      'getHistoryUsersLoading',
      'countPaginationUser'
    ]),

    searchByColumn() {
      if (this.full_name) {
        return this.getlistHistoryUsers.filter(item => {
          return item.user.toLowerCase().includes(this.full_name.toLowerCase())
        })
      }
      return this.getlistHistoryUsers
    }
  },
  watch: {
    full_name(value) {
      if (value) {
        this.$nextTick(() => {
          if (this.$refs.autofocus) {
            this.$refs.autofocus.focus()
          }
        })
      }
    }
  },
  async mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
    await this.fetchListHistoryUsers({
      page: this.page,
      per_page: this.per_page,
      sort_by: this.sort_by
    })
    this.initLoading = false
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>
<style lang="scss" scoped>
.history-users {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
  }
  .line-history {
    margin-top: 4px;
    margin-bottom: 7px;
  }
  .search {
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    .searchIcon {
      padding: 9px;
      border: 1px solid #d6d8db;
      font-size: 16px;
      cursor: pointer;
      background: #d6d8da17;
    }
    .input-search {
      border: 1px solid #dadada;
      outline: none;
      font-size: 16px;
      height: 40px;
      background: #fff;
      padding-left: 10px;
    }
    .margin-loading {
      margin-left: 135px;
    }
  }
  .table-history-users {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    color: #212529;
    border: 0;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #b9babb;
    margin-top: -8px;
    font-size: 12px;
    tbody {
      .nom_user,
      .nom_user_filtre {
        font-size: 13px;
        &.nom_user {
          text-transform: capitalize;
        }
      }
      .nom_user_lien {
        color: #404346;
        text-decoration: none;
        background-color: transparent;
        &:hover {
          padding: 2px 5px 4px;
          /* background-color: #9fa1a2; */
          border: 1px solid #404346;
          border-radius: 4px;
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    width: 100%;
    .loading {
      width: 50%;
    }
  }
}
</style>
<style lang="scss">
.content-tab-history-users {
  .width-th-nom {
    width: 12%;
  }
  .width-th-filtre {
    width: 20%;
    text-align: initial;
    text-transform: capitalize;
  }
  .width-th-type {
    width: 6%;
  }
  .width-th-lien {
    width: 4%;
  }
  .width-th-date {
    width: 10%;
  }
  .table.b-table.table-sm > thead > tr > [aria-sort].b-table-sort-icon-left,
  .table.b-table.table-sm > tfoot > tr > [aria-sort].b-table-sort-icon-left {
    background-position: left calc(3.3rem / 2) center;
    padding-left: calc(1.3rem + -0.35em);
  }
  .table thead th {
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
  }
  .table-sm th,
  .table-sm td {
    vertical-align: middle;
    border: 1px solid #dee2e6;
  }
}
</style>
